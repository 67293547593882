.pages-internal-heading {
  margin-bottom: 0;
  font-size: 16px !important;
  font-weight: 600;
}

.p-column-title {
  text-transform: uppercase;
  color: #505780;
}

.action-buttons {
  .p-button {
    background-color: transparent !important;
    border: none !important;
  }
}

.popup-header {
  place-content: flex-start;
  .p-dialog-header {
    text-align: left;
  }
}

label {
  color: #425466;
  font-weight: 600;
  font-size: 12px;
  font-weight: 400;
}

.field {
  margin-bottom: 0.5rem;
}

.p-inputtext {
  font-size: 0.9rem;
}

.p-calendar {
  .p-inputtext {
    border-right: 0;
    border-top-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .p-button.p-button-icon-only {
    width: auto;
    background: none !important;
    border: 1px solid #ced4da !important;
    border-left-width: 0 !important;

    svg {
      color: var(--body-color);
    }
  }
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
}

.file-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 0.5rem 0.5rem;
  border-radius: 4px;
  overflow: hidden;

  .none-input {
    display: none;
  }

  .attach-picture {
    cursor: pointer;
    margin-right: 10px;
    color: #000;
    background-color: #d1cbcb85;
    border: 1px solid #d1cbcb85;
    padding: 2px 5px;
    white-space: nowrap;
  }

  .file-name {
    font-size: 0.7rem;
    white-space: nowrap;
  }
}

.p-inputtextarea {
  margin-top: 0.5rem;
  width: 100%;
}

.p-icon-field {
  width: 100%;
}

.popup-header-text {
  text-transform: uppercase;
}

.add-new-dropdown {
  font-weight: 600;

  i {
    font-weight: 600;
    margin-right: 8px;
    margin-left: 8px;
  }
}

.custom-tag {
  padding: 0.2em 0.6em;
  border-radius: 20px;
  font-size: 12px;
  display: inline-block;
  font-weight: 600;
}

.tag-green {
  background-color: #e7f4ee;
  color: #0d894f;
}

.tag-red {
  background-color: #feedec;
  color: #f04438;
}

.filters-btn {
  color: #667085;
  font-weight: 500;
  background-color: var(--white-color) !important;
  border: 1px solid #ced4da !important;

  .p-button-label {
    font-weight: 500;
  }
  &:hover {
    color: #667085 !important;
  }
}

.filter-menu {
  width: 300px;
  padding: 10px 10px;

  .p-submenu-header {
    display: none;
  }
  .filters-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0.75rem 0.5rem;
    color: #343a40;
    background: #ffffff;
    font-weight: 700;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    p {
      margin-bottom: 0;
    }
  }
}

.leave-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .leave-card {
    border: 1px solid #e6edf3;
    border-radius: 8px;
    padding: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--white-color);
    overflow: hidden;
  }

  .leave-header {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    padding: 16px;
    background-color: #e5f6f6;
    border-bottom: 2px solid #e6e6e6;
  }

  .leave-body {
    display: flex;
    flex-direction: column;
  }

  .leave-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px;
    position: relative;
    border-bottom: 1px solid #e6e6e6;

    span {
      font-size: 14px;

      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }

  .leave-row:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #d9d9d9;
  }

  .leave-row:last-child {
    border-bottom: none;
  }

  .leave-value {
    font-weight: bold;
  }
}


.dialog-header-hidden {

  .p-dialog-header {
    padding: 0;
    height: 2vh;
    .p-dialog-header-icons {
  display: none;
    }

  }
}