$primary-color: #008578;
$secondary-color: #ffffff;
$text-color: #84818a;
$input-border-color: #d9d9d9;
$background-color: var(--primary-color);
$container-width: 1400px;
$breakpoint-md: 768px;
$breakpoint-1025: 1025px;

.auth-page {
  background-color: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 100vh;

  .link-a {
    padding-top: 8px;
    color: var(--primary-color);

    a {
      font-size: 12px !important;
      font-weight: 500;
      color: var(--primary-color) !important;
    }
  }

  .container {
    width: 100%;
    max-width: $container-width;
    overflow: hidden;
    margin: 0 auto;
    @media (max-width: $breakpoint-md) {
      width: 95%;
    }
  }

  .content-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $breakpoint-md) {
      grid-template-columns: 1fr;
      display: flex;
      flex-direction: column-reverse;
    }

    @media (max-width: 1400px) {
      padding: 30px;
    }
  }

  .p-component {
    width: 100% !important;
  }

  .sign-in-box {
    padding: 14rem 10rem;
    background-color: $secondary-color;
    border-radius: 30px;

    @media (max-width: $breakpoint-md) {
      padding: 10rem 3rem;
    }
    @media (max-width: $breakpoint-1025) {
      padding: 10rem 4rem;
    }

    h2 {
      color: var(--black-color);
      font-size: 36px;
      margin-bottom: 8px;
      font-weight: 600;
    }

    .create-account {
      color: var(--black-color);
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 600;
      padding: 10px 0px;
      a {
        color: var(--black-color);
        text-decoration: none;
      }
    }

    form {
      .input-group {
        margin-bottom: 28px;

        label {
          display: block;
          color: $text-color;
          font-size: 14px;
        }

        input {
          width: 100%;
          padding: 10px;
          background: #fff !important;
          font-size: 16px;
          border-bottom: 1.5px solid #d7caca !important;
          border: none;
          border-radius: unset;

          &:hover,
          &:focus {
            border-bottom: 1.5px solid #d7caca !important;
            box-shadow: unset;
          }
        }
      }

      .password-container {
        position: relative;

        .eye-icon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }

      .links {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: 14px;

        @media (max-width: 500px) {
          display: block;
        }

        a {
          color: $primary-color;
          text-decoration: none;
        }
      }

      .sign-in-button {
        background-color: $primary-color;
        color: $secondary-color;
        border: none;
        border-radius: 12px;
        padding: 14px 45px !important;
        font-size: 15px !important;
        cursor: pointer;
        width: 100%;
        white-space: nowrap;

        @media (max-width: 500px) {
          width: 100%;
          margin-top: 10px;
        }
      }

      .p-button {
        padding: 14px 45px !important;
        font-size: 15px !important;
      }

      .divider {
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 16px;

        p {
          margin: 0 10px;
          color: $text-color;
          font-size: 14px;
        }

        span {
          flex: 1;
          height: 1px;
          background-color: $input-border-color;
        }
      }

      .google-sign-in {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $secondary-color;
        color: var(--black-color);
        font-weight: 500;
        padding: 12px;
        border: 1px solid #d9d9d9a6;
        border-radius: 12px;
        width: 100%;
        font-size: 14px;
        box-shadow: 0px 4px -1px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        img {
          margin-right: 10px;
        }
      }

      .terms {
        font-size: 12px;
        color: #84818a;
        margin-top: 20px;
        font-weight: 400;

        a {
          color: $primary-color;
          text-decoration: none;
        }
      }

      .p-input-icon svg {
        color: #84818a;
        font-weight: 600;
      }
    }
  }

  .info-box {
    background-color: $background-color;
    color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 40px 100px;
    text-align: left;

    @media (max-width: $breakpoint-md) {
      padding: 40px 20px;
    }

    .logo {
      margin-bottom: 30px;
      // width: 300px;
    }

    h1 {
      font-size: 48px;
      margin-bottom: 20px;
      font-weight: 600;
      text-align: left;

      @media (max-width: $breakpoint-md) {
        font-size: 30px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

.p-icon-field {
  width: 100%;
}

.p-input-icon {
  position: absolute;
  right: 10px;
  top: 22px;

  svg {
    width: 16px;
    height: 16px;
    color: var(--primary-color);
  }
}

.p-float-label {
  width: 100%;
}
