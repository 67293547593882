.dashboard {
  .dashboard-screen {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px 0px 10px 0px;
    box-sizing: border-box;
    gap: 10px;

    
    @media (max-width: 768px) { 
    display: grid;
    }

    .time-section {
      display: flex;
      justify-content: flex-start;
      padding: 3px 0px;
    }

    .time-card {
      background-color: #ffffff;
      padding: 20px;
      text-align: center;
      border-radius: 8px;
      width: 250px;
      box-shadow: 0px -1px 8px rgb(103 103 103 / 10%);
      padding-top: 40px;

      @media (max-width: 768px) { 
        width: 100%;
      }
    }

    .time-header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      gap: 10px;

      .content {
        text-align: left;

        h4 {
          font-weight: 600;
          font-size: 24px;
          margin-bottom: 0 !important;
        }

        span {
          font-size: 12px;
          opacity: 0.65;
        }
      }
    }

    .time-header i {
      font-size: 24px;
      margin-right: 10px;
    }

    .time-body {
      margin: 30px 0px;
      font-size: 18px;
      font-weight: 500;
      text-align: left;
    }

    .stats-section {
      width: 100%;

      .stat-card {
        background-color: #ffffff;
        // padding: 20px;
        text-align: left;
        border-radius: 8px;
        // width: 200px;
        box-shadow: 0px -1px 8px rgb(103 103 103 / 10%);
        position: relative;
      }

      .stat-icon {
        position: absolute;
        top: 10px;
        right: 20px;
      }

      .stat-card p {
        margin: 0;
        font-weight: 500;
      }
      .p-card .p-card-title {
        font-size: 38px;
        font-weight: 600;
      }

      .stat-card small {
        display: flex;
        margin-top: 5px;
        color: #888;
        font-size: 12px;
        align-items: center;
        gap: 8px;
      }
    }
    .p-button {
      width: 100%;
    }
  }

  .positive-change {
    color: green;
  }

  .negative-change {
    color: red;
  }

  .p-card .p-card-content {
    padding: 0;
  }

  .p-chart canvas {
    height: 400px !important;
  }

  h6 {
    font-size: 16px;
    font-weight: 600;
  }
}
